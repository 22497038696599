<template>
  <div class="Room bg-white">
    <Row :gutter="8" class="px-1">
      <i-col span="24" class="align-justify">
        <Button type="default" icon="md-refresh" size="large" class="mr-1"></Button>
        <Button type="default" icon="md-add" size="large" class="mr-1" @click="addRoom"></Button>
        <i-input v-model="inputValue" size="large" style="width: 20rem" class="el-inline mr-1">
          <Button slot="append" icon="md-search" style="width: 2.5rem" @click="getRoomList"></Button>
        </i-input>
        <Button type="default" size="large">批量删除</Button>
      </i-col>
      <i-col span="12" class="align-justify">
        <Table
          highlight-row
          border
          stripe
          ref="roomTable"
          :loading="searching"
          :columns="tColumns"
          :data="tData"
          :height="theight"
          class="mt-1"
          @on-current-change="getCrurrentRow"
        >
          <div slot-scope="{row}" slot="action">
            <Button size="small" @click="remove(row)">删除</Button>
          </div>
        </Table>
        <Page
          ref="usersPagination"
          :total="recordTotal"
          :page-size="20"
          @on-change="changePage"
          @on-page-size-change="changeSize"
          show-total
          show-sizer
          show-elevator
          class="p-1 align-right"
        />
      </i-col>
      <i-col span="8" class="align-justify mt-1">
        <i-form :model="roomForm" :label-width="80">
          <FormItem label="地址">
            <i-select
              size="large"
              filterable
              clearable
              allow-create
              placeholder="新地址可直接输入"
              v-model="roomForm.address"
              @on-change="selectAddress"
              @on-create="createOption"
            >
              <Option v-for="(aitem,akey) in addressOption" :key="akey" :value="aitem">{{aitem}}</Option>
            </i-select>
          </FormItem>
          <FormItem label="房间数">
            <InputNumber size="large" :min="1" :step="1" v-model="roomForm.rooms"></InputNumber>
          </FormItem>
        </i-form>
        <div class="align-center">
          <Button size="large" type="primary" class="wideButton" @click="formSubmit()">提 交</Button>
        </div>
      </i-col>
    </Row>
  </div>
</template>

<script>
export default {
  name: "Room",
  data() {
    return {
      tColumns: [
        {
          type: "selection",
          width: 35,
          align: "center",
        },
        {
          type: "index",
          title: "序号",
          width: 45,
          align: "center",
        },
        {
          title: "地址",
          key: "address",
          minWidth: 300,
          ellipsis: true,
        },
        {
          title: "房间号",
          key: "roomCode",
          minWidth: 80,
        },
        {
          title: "操作",
          slot: "action",
          width: 57,
        },
      ],
      inputValue: "",
      searching: true,
      pageSize: 20,
      recordTotal: 0,
      pageNum: 1,
      tData: [],
      addressOption: ["南昌市西湖区某某路32号501室"],
      roomForm: {
        roomId: "",
        address: "",
        rooms: 0,
      },
      theight: window.innerHeight - 275,
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 275),
      false
	);
	this.getRoomList();
  },
  methods: {
    changeSize(e) {
      this.pageSize = e;
      this.getRoomList();
    },
    changePage(e) {
      this.pageNum = e;
      this.getRoomList();
    },
    getRoomList() {
      this.$get("Reserve/GetRoomList", {
        keyword: this.inputValue,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      }).then((res) => {
        this.recordTotal = res.data.total;
        this.tData = res.data.responseList;
        this.searching = false;
        this.$Message.success({
          content: "加载成功",
          duration: 3,
        });
      });
    },
    selectAddress(value) {
      this.roomForm.address = value;
    },
    // 添加地址
    createOption(value) {
      this.addressOption.push(value);
    },
    // 获取咨询室信息到表单
    getCrurrentRow(row) {
      this.roomForm = {
        roomId: row.roomId,
        address: row.address,
        rooms: row.rooms,
      };
      return this.roomForm;
    },
    // 创建咨询室时初始化表单
    addRoom() {
      this.roomForm = {
        roomId: "",
        address: "",
        room: "",
      };
    },
    // 提交表单
    formSubmit() {
      if (this.roomForm.roomId) {
        console.log("此为编辑咨询室");
      } else {
        console.log("此为添加咨询室");
      }
    },
  },
};
</script>

<style>
</style>
